




































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { rootModule } from '@/store';
import { msToTimeStr } from '@/util';

@Component({
  components: {
    DataTable,
    Modify,
  },
})
export default class extends Vue {
  runAttempts: API.RunAttempts.GET[] = []; // Only stores the current page of attempts!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyAttempt: API.RunAttempts.GET | null = null; // Attempt to be modified/deleted.
  additionalParams: Partial<API.TypesMap['runAttempts']['params']> = {};
  options = {
    apiName: 'runAttempts',
    socketName: 'runAttemptModified',
    idStr: 'id',
    embeds: [
      { name: 'player', socketName: 'playerModified', idStr: 'playerId' },
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
      { name: 'event', socketName: 'eventModified', idStr: 'eventId' },
    ],
    headers: [
      { text: 'Name', value: 'user.name' },
      { text: 'Event', value: 'event.name' },
      { text: 'Duration', value: 'duration' },
      { text: 'Finish Time', value: 'finishTime' },
      { text: 'Verified', value: 'verified' },
      { text: 'ID', value: 'id' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    modifyComponent: Modify,
    reloadOnPropChanged: ['verified', 'finishTime'],
  };
  dayjs = dayjs;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }
  get selectedPlayer(): number | null { return rootModule.selectedPlayer; }

  @Watch('selectedPlayer', { immediate: true })
  onSelectedPlayerChange(val: number | null): void {
    if (typeof val === 'number') Vue.set(this.additionalParams, 'playerId', val);
    else Vue.set(this.additionalParams, 'playerId', undefined);
  }

  unverifiedCheckChange(val: boolean): void {
    if (val) Vue.set(this.additionalParams, 'verified', false);
    else Vue.set(this.additionalParams, 'verified', undefined);
  }

  finishedCheckChange(val: boolean): void {
    if (val) Vue.set(this.additionalParams, 'finished', true);
    else Vue.set(this.additionalParams, 'finished', undefined);
  }
}
