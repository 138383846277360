























import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/components/Admin/DataTable.vue';
import { rootModule } from '@/store';
import { API } from '@/types';
import dayjs from 'dayjs';
import { msToTimeStr } from '@/util';
import clone from 'clone';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  placements: API.LeaderboardPlacements.GET[] = [];
  tableOptions = { page: 1, itemsPerPage: 10 };
  options = {
    apiName: 'leaderboardPlacements',
    socketName: 'leaderboardPlacementModified',
    idStr: 'playerId',
    embeds: [
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
    ],
    headers: [
      { text: 'Position', value: 'position' },
      { text: 'Name', value: 'user.name' },
      { text: 'Time', value: 'time' },
      { text: 'Additional Data', value: 'additionalData' },
      { text: 'Attempt ID', value: 'attemptId' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    hideActions: true,
    hideAdd: true,
  };
  dayjs = dayjs;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  // This causes the table to always display the placements in position order
  // even if not stored in the correct order.
  get placementsSorted(): API.LeaderboardPlacements.GET[] {
    return clone(this.placements).sort((a, b) => a.position - b.position);
  }
}
