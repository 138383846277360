





















import { Vue, Component } from 'vue-property-decorator';
import EventSelect from '@/components/EventSelect.vue';
import PlayerSelect from '@/components/Admin/PlayerSelect.vue';
import TableComponent from './components/Table.vue';
import Leaderboard from './components/Leaderboard.vue';
import { rootModule } from '@/store';

@Component({
  components: {
    EventSelect,
    PlayerSelect,
    TableComponent,
    Leaderboard,
  },
})
export default class extends Vue {
  get selectedEvent(): number | null { return rootModule.selectedEvent; }
  get isEventSelected(): boolean { return typeof this.selectedEvent === 'number'; }
}
