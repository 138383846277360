






















import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import SwitchInput from '@/components/Admin/SwitchInput.vue';
import PlayerInput from '@/components/Admin/PlayerInput.vue';
import { msToTimeStr, removeDBTimestamps, timeStrToMS } from '@/util';
import { apiModify } from '@/api';

@Component({
  components: {
    ModifyDialog,
    TextInput,
    SwitchInput,
    PlayerInput,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.RunAttempts.GET | undefined;
  attempt: API.RunAttempts.PUT = {};
  attemptId: number | null = null;
  durationStr: string | null = null;
  finishTimeStr: string | null = null;
  err: boolean | string = false;

  get additionalData(): string | null {
    return this.attempt.additionalData
      ? JSON.stringify(this.attempt.additionalData)
      : null;
  }
  set additionalData(val: string | null) {
    this.attempt.additionalData = val ? JSON.parse(val) : null;
  }

  get duration(): string | null {
    return this.durationStr;
  }
  set duration(val: string | null) {
    this.durationStr = val;
    this.attempt.duration = val ? timeStrToMS(val) : 0;
  }

  // Correctly formats anything entered into the "Duration" field when clicked off.
  durationBlur(): void {
    this.duration = typeof this.attempt.duration === 'number'
      ? msToTimeStr(this.attempt.duration)
      : null;
  }

  get finishTime(): string | null {
    return this.finishTimeStr;
  }
  set finishTime(val: string | null) {
    this.finishTimeStr = val;
    this.attempt.finishTime = val ? timeStrToMS(val) : null;
  }

  // Correctly formats anything entered into the "Finish Time" field when clicked off.
  finishTimeBlur(): void {
    this.finishTime = this.attempt.finishTime ? msToTimeStr(this.attempt.finishTime) : null;
  }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val) {
      if (this.modifyItem) {
        this.attempt = removeDBTimestamps(this.modifyItem);
        this.attemptId = this.modifyItem.id;
        this.duration = typeof this.attempt.duration === 'number'
          ? msToTimeStr(this.attempt.duration)
          : null;
        this.finishTime = typeof this.attempt.finishTime === 'number'
          ? msToTimeStr(this.attempt.finishTime)
          : null;
      }
    }
  }

  cancel(): void {
    this.duration = null;
    this.finishTime = null;
    this.attempt = {};
    this.attemptId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      const { data } = await apiModify('runAttempts', this.attemptId, this.attempt);
      this.duration = null;
      this.finishTime = null;
      this.attempt = {};
      this.attemptId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
